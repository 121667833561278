import React from 'react';

import {
	EditOutlined,
	RollbackOutlined,
	SearchOutlined,
	UserOutlined,
} from '@ant-design/icons';
import { useQuery } from '@tanstack/react-query';
import { Avatar, Button, Form, Input, Popconfirm, Space, Tooltip } from 'antd';
import axios, { AxiosError } from 'axios';

import { formatNumber } from '@/lib/helpers/numFormat';
import client from '@/lib/http/client';

import style from './style.module.css';

import { IInfluencers } from '.';
import Platforms from '../../platforms';

interface IProps {
	rowKey: string;
	placeholder: string;
	platform?: any | null;
	regexp: string;
	setInfluencers: React.Dispatch<React.SetStateAction<IInfluencers>>;
}

enum IState {
	Create = 1,
	Edit = 2,
	View = 3,
}

const isTwitter = (itemRegex: string) => itemRegex === 'twitter';
const twitterRegex = '((twitter)|(x))';

const CardInput = (props: IProps) => {
	const [form] = Form.useForm();
	const [state, setState] = React.useState(IState.Create);
	const [search, setSearch] = React.useState('');
	const [loading, setLoading] = React.useState(false);

	const zx = form.getFieldError('url');

	React.useEffect(() => {
		props.setInfluencers((x) => {
			return {
				...x,
				[props.rowKey]: {
					...x[props.rowKey],
					errors: {
						...x[props.rowKey].errors,
						[props.regexp]: zx?.length > 0 ? zx[0] : loading ? 'loading' : '',
					},
				},
			};
		});
	}, [zx.length, form.getFieldValue('url'), loading]);

	const creator = useQuery({
		queryKey: ['creator', search],
		cacheTime: 0,

		// keepPreviousData: false,
		queryFn: async () => {
			try {
				if (search?.length === 0) {
					return;
				}
				setLoading(true);
				const data = await client.get(`/creators/url?q=${search}`);
				console.log(data);

				props.setInfluencers((x) => {
					const platforms = {
						...x[props.rowKey].platforms,
						...data?.data?.platforms,
					};

					return {
						...x,
						[props.rowKey]: {
							...x[props.rowKey],
							...data.data,
							platforms,
						},
					};
				});

				setState(IState.View);

				return data.data;
			} catch (err: any) {
				if (axios.isAxiosError(err)) {
					if (err.response?.data?.statusCode === 404) {
						return form.setFields([
							{
								name: ['url'],
								errors: [
									'Creator account not found - please check and try again',
								],
							},
						]);
					} else {
						return form.setFields([
							{
								name: ['url'],
								errors: [err.response?.data.message],
							},
						]);
					}
				}

				if (err?.message?.includes("(reading 'status')")) {
					form.setFields([
						{
							name: ['url'],
							errors: ['Please try again'],
						},
					]);
					return setSearch('');
				}
				return err;
			} finally {
				setLoading(false);
			}
		},
		enabled: search?.length > 0 && state !== IState.View && zx?.length === 0,
	});

	return (
		<div>
			{state === IState.Create && (
				<Form
					form={form}
					onFinish={(v) => {
						setSearch(v.url);
					}}
				>
					<Form.Item
						style={{ marginBottom: '0px' }}
						name={'url'}
						rules={[
							{
								pattern: new RegExp(
									//`^(https:\/\/(?:www\.)?(${props.regexp.toLocaleLowerCase()}\\.com))`,
									`^(https:\/\/(?:www\.)?(${
										isTwitter(props?.regexp)
											? twitterRegex
											: props.regexp.toLocaleLowerCase()
									}\\.com))(?:\/[^\\s]+)$`,
								),
								message: `Please enter the channel name in the following format: "https://www.${props.regexp.toLocaleLowerCase()}.com/@creatorID..." `,
							},
						]}
					>
						<div className={style.cardInput}>
							<Input
								disabled={loading}
								style={{ margin: '0px' }}
								placeholder={props.placeholder}
								onBlur={(e) => {
									setSearch(e.target.value);
								}}
							></Input>
							<Tooltip title="search">
								<Button
									loading={loading}
									type="primary"
									shape="circle"
									htmlType="submit"
									icon={<SearchOutlined />}
								/>
							</Tooltip>
						</div>
					</Form.Item>
				</Form>
			)}

			{state === IState.Edit && (
				<Form
					form={form}
					onFinish={(v) => {
						setSearch(v.url);
					}}
				>
					<Form.Item
						name={'url'}
						initialValue={props.platform.url}
						style={{ marginBottom: '0px' }}
						rules={[
							{
								pattern: new RegExp(
									//`^(https:\/\/(?:www\.)?(${props.regexp.toLocaleLowerCase()}\\.com))`,
									`^(https:\/\/(?:www\.)?(${props.regexp.toLocaleLowerCase()}\\.com))(?:\/[^\\s]+)$`,
								),
								message: `Please enter the channel name in the following format: "https://www.${props.regexp.toLocaleLowerCase()}.com/@creatorID..." `,
							},
						]}
					>
						<div className={style.cardInput}>
							<Input
								disabled={loading}
								style={{ margin: '0px' }}
								placeholder={props.placeholder}
								defaultValue={props.platform.url}
								onBlur={(e) => {
									if (e.target.value !== '') {
										setSearch(e.target.value);
									}
								}}
							></Input>

							<Space direction="horizontal" size={15}>
								<Tooltip title="cancel">
									<Button
										loading={loading}
										type="dashed"
										shape="circle"
										onClick={() => setState(IState.View)}
										icon={<RollbackOutlined />}
									/>
								</Tooltip>
								<Tooltip title="search">
									<Button
										loading={loading}
										type="primary"
										shape="circle"
										htmlType="submit"
										icon={<SearchOutlined />}
										// disabled={search === props.platform?.url}
									/>
								</Tooltip>
							</Space>
						</div>
					</Form.Item>
				</Form>
			)}

			{state === IState.View && (
				<div className={style.cardView}>
					<Space direction="horizontal" style={{ cursor: 'pointer' }} size={15}>
						<Avatar
							size={36}
							src={
								props.platform.profileImageURL?.startsWith('http')
									? props.platform.profileImageURL
									: process.env.REACT_APP_CDN_STORAGE +
									  props.platform.profileImageURL
							}
							icon={<UserOutlined />}
						></Avatar>
						<h3>{props.platform.username}</h3>
					</Space>

					<div>
						<Space
							direction="horizontal"
							style={{ cursor: 'pointer' }}
							size={15}
						>
							<Platforms platform={props.platform.url} />
							<div>
								{formatNumber(
									props.platform?.connections?.followers ||
										props.platform?.connections?.subscribers ||
										'n/a',
								) + ' followers'}
							</div>
						</Space>
					</div>

					<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
						<Tooltip title="edit">
							<Button
								onClick={() => {
									setState(IState.Edit);
								}}
								loading={loading}
								type="dashed"
								shape="circle"
								htmlType="submit"
								icon={<EditOutlined />}
								// disabled={search === props.platform?.url}
							/>
						</Tooltip>
					</div>
				</div>
			)}
		</div>
	);
};

export default CardInput;
