import React, { useEffect, useState } from 'react';

import { Statistic, StatisticProps } from 'antd';
import CountUp from 'react-countup';

export const CreditsCounter = ({ count }: { count: number }) => {
	const [prevTotalReports, setPrevTotalReports] = useState(0);

	useEffect(() => {
		setPrevTotalReports(count);
	}, [count]);

	return (
		<div>
			<div>
				Available Report Credits:{' '}
				<CountUp
					start={prevTotalReports}
					end={count > 0 ? count : (0 as number)}
					separator=","
				/>
			</div>
		</div>
	);
};
