import React from 'react';

import { DeleteOutlined } from '@ant-design/icons';
import { useQuery } from '@tanstack/react-query';
import {
	Button,
	DatePicker,
	Divider,
	Popconfirm,
	Select,
	Space,
	Tag,
} from 'antd';
import dayjs from 'dayjs';

import client from '@/lib/http/client';

import style from './style.module.css';

import { IInfluencers } from '.';
import CardInput from './card-input';

interface IProps {
	influencersLength: number;
	rowKey: string;
	influencer: any;
	setInfluencers: React.Dispatch<React.SetStateAction<IInfluencers>>;
	onDelete: (key: string) => void;
}

const InfluencerCard = (props: IProps) => {
	const tags = useQuery({
		queryKey: ['tags'],
		queryFn: () => client.get(`/reports/tags`),
	});
	const [open, setOpen] = React.useState<string | undefined>(undefined);
	const selectRef = React.useRef(null);

	const [options, setOptions] = React.useState([
		{
			label: 'Common',
			options: [
				{ label: '1 Month', value: '31' },
				{ label: '3 Month', value: '92' },
				{ label: '6 Month', value: '153' },
				{ label: '12 Month', value: '366' },
			],
		},
		{
			label: 'Custom',
			options: [{ label: 'From account start', value: 'Since Start' }],
		},
		{
			label: 'Custom',
			value: '',
			disabled: true,
		},
	]);

	const onScrapeToChange = (event: string, id: string) => {
		props.setInfluencers((prev) => ({
			...prev,
			[id]: { ...prev[id], scrapeTo: event },
		}));
	};
	const onTagChange = (event: string[], id: string) => {
		props.setInfluencers((prev) => ({
			...prev,
			[id]: { ...prev[id], tags: event },
		}));
	};
	const tagRender = (props: {
		label: any;
		value: any;
		closable: any;
		onClose: any;
	}) => {
		const { label, value, closable, onClose } = props;
		return (
			<Tag
				color="blue"
				closable={closable}
				onClose={onClose}
				style={{ marginRight: 3 }}
			>
				{value}
			</Tag>
		);
	};

	return (
		<div className={style.card}>
			<Select
				mode="tags"
				showSearch={false}
				suffixIcon={null}
				tagRender={tagRender}
				style={{ width: '100%' }}
				placeholder="Report tags"
				options={tags.data?.data?.map((tag: any) => ({
					label: tag,
					value: tag,
				}))}
				onChange={(e) => {
					onTagChange(e, props.rowKey);
				}}
				// onChange={setSelectedTags} // Update the selected tags whenever they change
			></Select>
			<CardInput
				rowKey={props.rowKey}
				placeholder={
					'https://youtube.com/@...'
					// + props.rowKey?.split(' ').join('')
				}
				setInfluencers={props.setInfluencers}
				platform={props.influencer?.platforms?.Youtube || {}}
				regexp="youtube"
			/>
			<CardInput
				rowKey={props.rowKey}
				placeholder={
					'https://instagram.com/@...'
					//  + props.rowKey?.split(' ').join('')
				}
				setInfluencers={props.setInfluencers}
				platform={props.influencer?.platforms?.Instagram || {}}
				regexp="instagram"
			/>
			<CardInput
				rowKey={props.rowKey}
				placeholder={
					'https://tiktok.com/@...'
					// + props.rowKey?.split(' ').join('')
				}
				setInfluencers={props.setInfluencers}
				platform={props.influencer?.platforms?.TikTok || {}}
				regexp="tiktok"
			/>

			{/* <CardInput
				rowKey={props.rowKey}
				placeholder={
					'https://twitter.com/@...'
					// + props.rowKey?.split(' ').join('')
				}
				setInfluencers={props.setInfluencers}
				platform={props.influencer?.platforms?.Twitter || {}}
				regexp="twitter"
			/> */}

			<div className={style.delete}>
				<Space direction="horizontal" size={20}>
					<Select
						// disabled={props.rowKey === 'empty'}
						ref={selectRef}
						style={{ minWidth: 200 }}
						placeholder="custom dropdown render"
						defaultValue={'31'}
						optionLabelProp="label"
						value={props.influencer.scrapeTo}
						open={open === props.rowKey}
						onChange={(e) => {
							onScrapeToChange(e, props.rowKey);
							setOpen(undefined);
							if (selectRef.current) {
								(selectRef.current as any).blur();
							}
						}}
						onSelect={() => setOpen(undefined)}
						onFocus={() => setOpen(props.rowKey)}
						onBlur={() => setOpen(undefined)}
						dropdownRender={(menu) => (
							<>
								{menu}
								<Divider style={{ margin: '8px 0' }} />
								<Select.Option>All Data</Select.Option>
								<Space style={{ padding: '0 8px 4px' }}>
									<DatePicker
										clearIcon={false}
										onFocus={() => setOpen(props.rowKey)}
										onBlur={() => setOpen(undefined)}
										disabledDate={(current) => {
											return current && current > dayjs().add(-1, 'day');
										}}
										format={(v) => dayjs(v.toString()).format('DD MMM YYYY')}
										onChange={(v) => {
											onScrapeToChange(
												getDays(v?.toString() || ''),
												props.rowKey,
											);
											setOpen(undefined);
											setOptions(
												// @ts-ignore
												options.map((opt, i) => {
													if (i !== options.length - 1) return opt;
													return {
														label:
															'From - ' +
															dayjs(v?.toString()).format('DD MMM YYYY'),
														value: getDays(v?.toString() || ''),
													};
												}),
											);
										}}
									/>
								</Space>
							</>
						)}
						options={options}
					/>

					{props.influencersLength > 1 && (
						<Popconfirm
							title="Delete"
							description="Are you sure to delete?"
							okText="Yes"
							cancelText="No"
							onConfirm={() => {
								props.onDelete(props.rowKey);
							}}
						>
							{}
							<Button danger shape="circle" icon={<DeleteOutlined />} />
						</Popconfirm>
					)}
				</Space>
			</div>
		</div>
	);
};

export default InfluencerCard;

function getDays(v: string): string {
	// Get difference between last video and live video in days
	const diff =
		(new Date().getTime() - dayjs(v).toDate().getTime()) / (1000 * 3600 * 24);

	return diff.toFixed(0);
}
