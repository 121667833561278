import React, { useEffect } from 'react';

import {
	Button,
	Card,
	Checkbox,
	Col,
	Form,
	Input,
	Row,
	Typography,
} from 'antd';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import client from '@/lib/http/client';

import style from './style.module.css';

import logo from './logo.png';

// ...

const Login = () => {
	const location = useLocation();
	const userEmail = location.state?.email || '';
	const [form] = Form.useForm();
	const [loading, setLoading] = React.useState(false);
	const navigate = useNavigate();
	useEffect(() => {
		if (userEmail) {
			form.setFieldsValue({
				email: userEmail,
			});
		}
	}, [userEmail]);

	return (
		<Card
			title={
				<div className={style.wrapper}>
					<div className={style.logo}>
						<img src={logo} alt="" />
					</div>
					<div className={style.title}>
						<h2>Login</h2>
					</div>
				</div>
			}
			hoverable
			style={{ minWidth: '428px' }}
		>
			<Form
				layout="vertical"
				form={form}
				onFinish={(values) => {
					setLoading(true);
					client
						.post('/auth/login', values)
						.then(() => {
							navigate('/app/dashboard/creators');
						})
						.catch((err: any) => {
							setLoading(false);
							console.log(err);
							if (
								err.response?.data.message === 'Unauthorized' ||
								err.response?.data.message === 'Incorrect credentials' ||
								err.response?.data.message === 'login required'
							) {
								form.setFields([
									{
										name: 'email',
										errors: ['Email or password is incorrect'],
									},
									{
										name: 'password',
										errors: ['Email or password is incorrect'],
									},
								]);
							}
						});
				}}
			>
				<Form.Item
					label="Email"
					name="email"
					rules={[
						{
							required: true,
							message: 'Email is required',
						},
					]}
				>
					<Input placeholder="" defaultValue={userEmail} type="email" />
				</Form.Item>

				<Form.Item
					label="Password"
					name="password"
					rules={[{ required: true, message: 'Password is required' }]}
				>
					<Input.Password />
				</Form.Item>

				<Form.Item label="" name="remember" valuePropName="checked">
					<Checkbox name="remember">Remember me</Checkbox>
				</Form.Item>

				<Form.Item>
					<Button type="primary" htmlType="submit" block loading={loading}>
						Login
					</Button>
				</Form.Item>

				<Form.Item style={{ textAlign: 'center' }}>
					{/* TODO register link */}
					<Typography.Text>
						Don't have an account?{' '}
						<Typography.Link onClick={() => navigate('/auth/register')}>
							{' '}
							Register here
						</Typography.Link>
					</Typography.Text>
				</Form.Item>
				<Form.Item style={{ textAlign: 'center' }}>
					<Typography.Text>
						<Typography.Link onClick={() => navigate('/auth/reset-password')}>
							Forgot password?
						</Typography.Link>
					</Typography.Text>
				</Form.Item>
			</Form>
		</Card>
	);
};

export default Login;
