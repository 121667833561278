import React, { useCallback, useState } from 'react';

import { CloseCircleOutlined, EditOutlined } from '@ant-design/icons';
import { useQuery } from '@tanstack/react-query';
import { Button, Drawer, Space, Table, Tag, Tooltip } from 'antd';
import { Input } from 'antd';

import client from '@/lib/http/client';

import style from './style.module.css';

import AddUser from './add-user';
import SearchFilter from './search';
import UserDetail from './user-detail';

const { Search } = Input;
const UsersView = () => {
	const [current, setCurrent] = React.useState<number>(0);
	const [query, setQuery] = React.useState<string>('');
	const [sorting, setSorting] = React.useState<Array<string>>(['']);

	const pageSize = 15;

	const fetchUsers = (page: number, search: string, sorting: Array<string>) => {
		return client.get<any[]>(
			`/users?q=${search}&p=${page}&l=${pageSize}&srt=${sorting}`,
		);
	};
	const users = useQuery({
		queryKey: ['users', current, query, sorting],
		queryFn: () => fetchUsers(current, query, sorting),
	});

	const [open, setOpen] = React.useState(false);
	const [edit, setEdit] = React.useState(false);
	const [userId, setUser] = React.useState<any>('');

	const onSearch = async (text: string) => {
		if (text.length > 2) {
			setQuery(text);
		}

		if (!text.length) {
			setQuery('');
		}
	};
	const onSorter = (sortValue: string) => {
		setSorting([sortValue]);
	};

	const onOpen = (user: string) => {
		setUser(user);
		setOpen(true);
	};

	const onClose = () => {
		setUser('');
		setOpen(false);
		setEdit(false);
	};

	const onEdit = () => {
		setEdit(!edit);
	};

	return (
		<div
			style={{ position: 'relative', overflow: 'hidden', minHeight: '700px' }}
		>
			<div className={style.search}>
				<SearchFilter
					value=""
					onChange={onSearch}
					placeholder="Search by email or name"
				/>
				<AddUser />
			</div>

			<Table
				loading={users.isLoading}
				dataSource={users.data?.data}
				bordered
				pagination={{
					hideOnSinglePage: true,
					pageSize: pageSize,
					onChange(page) {
						setCurrent(page);
					},
				}}
				onChange={(pagination: any, filters: any, sorter: any) => {
					let order = sorter.order === 'ascend' ? 'ASC' : 'DESC';
					setSorting([sorter.columnKey, order]);
				}}
				rowKey={(row) => row?.id + row?.role}
			>
				<Table.Column
					title="Full Name"
					dataIndex="full_name"
					key="full_name"
					sorter
				/>
				<Table.Column title="Email" dataIndex="email" key="email" sorter />
				<Table.Column
					title="Active"
					key="active"
					align="center"
					sorter
					render={(value) => {
						return value.active ? (
							<Tag color="green">active</Tag>
						) : (
							<Tag color="red">inactive</Tag>
						);
					}}
				/>
				<Table.Column title="Role" dataIndex="role" key="role" sorter />
				<Table.Column
					title="Last login"
					key="last_login"
					render={(value) => {
						return value.last_login
							? new Date(value.last_login).toLocaleString()
							: '-';
					}}
					sorter
				/>

				<Table.Column
					width={150}
					align="center"
					title="Actions"
					key="actions"
					ellipsis
					fixed="right"
					render={(value) => {
						return (
							<Space.Compact
								block
								style={{ display: 'flex', justifyContent: 'center' }}
							>
								<Tooltip title="View Details">
									<Button
										icon={<EditOutlined />}
										onClick={() => onOpen(value.id)}
									/>
								</Tooltip>
							</Space.Compact>
						);
					}}
				/>
			</Table>

			<Drawer
				title="User detail"
				width={'80%'}
				placement="right"
				closable={false}
				onClose={onClose}
				open={open}
				getContainer={false}
				headerStyle={{ backgroundColor: edit ? 'orange' : 'white' }}
				extra={
					<Space>
						<Button onClick={onEdit}>
							<EditOutlined /> Edit
						</Button>

						<Button onClick={onClose}>
							<CloseCircleOutlined /> Close
						</Button>
					</Space>
				}
			>
				<UserDetail userId={userId} edit={edit} />
			</Drawer>
		</div>
	);
};

export default UsersView;
