import React, { useEffect, useState } from 'react';

import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { useQuery } from '@tanstack/react-query';
import {
	Button,
	Card,
	Col,
	Divider,
	Form,
	Input,
	InputNumber,
	Radio,
	Row,
	Select,
	Space,
	StepProps,
	Steps,
	message,
} from 'antd';
import { useForm } from 'antd/es/form/Form';
import FormItem from 'antd/es/form/FormItem';
import { values } from 'lodash';
import { useNavigate } from 'react-router-dom';

import { UserContext } from '@/App';
import client from '@/lib/http/client';

import style from './style.module.css';

const filterOption = (
	input: string,
	option?: { label: string; value: string },
) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

const emailValidator =
	/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

const OnBoarding = () => {
	const navigate = useNavigate();
	const { user, setUser } = React.useContext(UserContext);
	const userEmail = user.email;
	const [form] = useForm();
	const [current, setCurrent] = useState(
		+(localStorage.getItem('current_step') || 0),
	);
	const [formState, setFormState] = useState<any>({});
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		form.setFieldsValue({
			billing_email_address: userEmail || '',
		});
	}, [userEmail]);

	const countries = useQuery({
		queryKey: ['countries'],
		queryFn: () => client.get(`/countries`),
	});

	const currentCountry = useQuery({
		queryKey: ['country'],
		queryFn: () => client.get(`/countries/country`),
	});

	React.useEffect(() => {
		if (!!countries.data && !!currentCountry.data) {
			const countryId =
				// currentCountry.data?.data.status !== 'fail' &&
				countries.data?.data.find(
					(c: any) => c.country_name === currentCountry.data?.data.country,
					// (c: any) => c.country_name === 'Spain',
				)?.id || null;

			if (countryId) {
				setFormState({ ...formState, country_id: countryId });
				form.setFieldValue('country', countryId);
			}
		}
	}, [countries.isFetched, currentCountry.isFetched]);

	const filterOption = (
		input: string,
		option?: { label: string; value: string },
	) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

	const steps = [
		{
			// TODO role api
			title: 'Role',
			content: (
				<FormItem
					// help={
					// 	<span style={{ fontSize: '12px' }}>
					// 		E.g. CEO, Strategy, Executive, Head of operations, etc.
					// 	</span>
					// }
					// label="Role"
					name={'role'}
					rules={[{ required: true, message: 'Role is required' }]}
				>
					<Input
						placeholder="E.g. CEO, Strategy, Executive, Head of operations, etc."
						name="role"
						autoFocus
					></Input>
				</FormItem>
			),
			heading: 'What is your role in the organisation?',
			// title: '',
		},
		{
			title: 'Description',
			content: (
				<Form.Item
					// label="Describe yourself"
					name="organisation_type_id"
					// help={<span style={{ fontSize: '12px' }}>Describe yourself</span>}
					rules={[{ required: true, message: 'Please select an option!' }]}
				>
					<Radio.Group buttonStyle="solid">
						<Space direction="horizontal">
							<Radio.Button value={1}>In-house team or brand</Radio.Button>
							<Radio.Button value={2}>An External Agency</Radio.Button>
							<Radio.Button value={3}>Creator</Radio.Button>
							<Radio.Button value={4}>Other</Radio.Button>
						</Space>
					</Radio.Group>
				</Form.Item>
			),
			heading: 'Which describes you best?',
		},
		{
			title: 'About',
			heading: 'About Company',
			content: (
				<>
					<FormItem
						label="Company Name"
						// help="Company name"
						name={'organisation_name'}
						rules={[{ required: true, message: 'Company name required' }]}
					>
						<Input name="organisation_name" autoFocus></Input>
					</FormItem>
					<FormItem
						label="Number of employees"
						name={'employee_count'}
						rules={[
							{ required: true, message: 'Number of employees required' },
						]}
					>
						<Radio.Group buttonStyle="solid">
							<Space direction="horizontal">
								<Radio.Button value="1-10">1-10</Radio.Button>
								<Radio.Button value="10-25">10-25</Radio.Button>
								<Radio.Button value="25-75">25-75</Radio.Button>
								<Radio.Button value="75+">75+</Radio.Button>
							</Space>
						</Radio.Group>
					</FormItem>

					<FormItem
						label="Number of offices"
						// help="Company name"
						name={'offices_count'}
						rules={[{ required: true, message: 'Number of offices required' }]}
					>
						<InputNumber min={1} name="offices_count"></InputNumber>
					</FormItem>
				</>
			),
		},
		{
			title: 'Location',
			heading: 'Where you are located?',
			content: (
				<>
					<Row gutter={16}>
						<Col flex={1}>
							<FormItem
								label="Address 1"
								name={'address_1'}
								rules={[{ required: true, message: 'Address required' }]}
							>
								<Input name="address_1" autoFocus></Input>
							</FormItem>
						</Col>
						<Col flex={1}>
							<FormItem label="Address 2" name={'address_2'}>
								<Input name="address_2"></Input>
							</FormItem>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col flex={1} md={{ span: 12 }} sm={{ span: 12 }} xs={{ span: 24 }}>
							<FormItem
								label="Country"
								name={'country'}
								rules={[{ required: true, message: 'Country required' }]}
							>
								{/* <Input name="country"></Input> */}
								<Select
									loading={countries.isLoading && currentCountry.isLoading}
									showSearch
									placeholder="Select country"
									optionFilterProp="children"
									onChange={(value: number) => {
										setFormState({ ...formState, country_id: value });
									}}
									value={formState.country_id}
									// onChange={onChange}
									// onSearch={onSearch}
									filterOption={filterOption}
									defaultValue={formState.country_id}
									options={countries.data?.data.map((country: any) => ({
										value: country.id,
										label: country.country_name,
									}))}
								/>
							</FormItem>
						</Col>
						<Col flex={1} md={{ span: 12 }} sm={{ span: 12 }} xs={{ span: 24 }}>
							<FormItem
								label="City"
								name={'city'}
								rules={[{ required: true, message: 'City required' }]}
							>
								<Input name="city"></Input>
							</FormItem>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col flex={1} md={{ span: 12 }} sm={{ span: 12 }} xs={{ span: 24 }}>
							<FormItem
								label="Postal Code"
								name={'postcode'}
								rules={[{ required: true, message: 'Postal code requried' }]}
							>
								<Input name="postcode"></Input>
							</FormItem>
						</Col>
					</Row>
				</>
			),
		},
		{
			title: 'Teammates',
			heading: 'Invite your teammates?',
			content: (
				<Form.List name="invite_email" initialValue={['']}>
					{(fields, { add, move, remove }, meta) => {
						return (
							<>
								<div className={style.invite_emails}>
									{fields.map((field) => (
										<Form.Item
											{...field}
											rules={[
												{
													pattern: emailValidator,
													message: 'Invalid email address',
													validateTrigger: 'onFocusChange',
												},
											]}
										>
											<Input
												autoFocus
												placeholder="Enter your teammates email addresses"
												addonAfter={
													<MinusOutlined onClick={() => remove(field.name)} />
												}
												onKeyDown={({ key }) => {
													if (
														key === 'Enter' &&
														// if the current email isnt empty

														fields[fields.length - 1] !== undefined &&
														fields[fields.length - 1] !== null
													)
														add('');
												}}
											/>
										</Form.Item>
									))}
								</div>
								<Form.Item>
									<Button
										type="dashed"
										onClick={() => add()}
										icon={<PlusOutlined />}
									/>
								</Form.Item>
							</>
						);
					}}
				</Form.List>
			),
		},
		{
			title: 'Billing',
			heading: 'Your billing information',
			content: (
				<>
					<FormItem
						label="Billing Email"
						name={'billing_email_address'}
						rules={[
							{ required: true, message: 'Billing email address required' },
						]}
					>
						<Input name="billing_email_address" autoFocus></Input>
					</FormItem>
					<FormItem
						label="Company TAX ID (EG: VAT)"
						name={'tax_id'}
						// rules={[{ required: false, message: 'Company Tax ID required' }]}
					>
						<Input name="tax_id"></Input>
					</FormItem>
				</>
			),
		},
		{
			title: 'Payment',
			heading: 'Your payment information',
			content: (
				<>
					<FormItem label="STRIPE - TBC" name={'finish3'}>
						<Input
							name="finish1"
							disabled={true}
							value={'To be completed'}
							placeholder="To be completed"
						></Input>
					</FormItem>
				</>
			),
		},
	];

	React.useEffect(() => {
		const state = localStorage.getItem('onboarding_form_state');
		if (state) {
			setFormState(JSON.parse(state));
		}
	}, []);

	const next = () => {
		const values = form.getFieldsValue();
		form
			.validateFields()
			.then((v) => {
				setFormState((p: any) => ({ ...formState, ...values }));
				setCurrent(current + 1);
				localStorage.setItem(
					'onboarding_form_state',
					JSON.stringify({ ...formState, ...values }),
				);
				localStorage.setItem('current_step', (current + 1).toString());
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const prev = () => {
		localStorage.setItem('current_step', (current - 1).toString());
		setCurrent(current - 1);
	};

	const items: StepProps[] = steps.map((item: StepProps) => ({
		key: item.title,
		title: item.title,
		description: item.title,
		// subTitle: item.title,
	}));

	const contentStyle: React.CSSProperties = {
		height: '100%',
	};

	return (
		<Card title="ONBOARDING" size="default">
			<div className={style.layout}>
				<Steps
					current={current}
					items={items}
					direction="vertical"
					size="small"
				/>
				<Form
					disabled={loading}
					layout="vertical"
					className={style.form}
					form={form}
					initialValues={JSON.parse(
						localStorage.getItem('onboarding_form_state') || '{}',
					)}
					onFinish={(values) => {
						formState.invite_email = formState.invite_email.filter(
							(v: string) => v !== '',
						);
						setLoading(true);
						client
							.post('/customers', formState)
							.then((d) => {
								localStorage.removeItem('onboarding_form_state');
								localStorage.removeItem('current_step');
							})
							.then(() => {
								return client.get('/auth/new-token').then((d) => {
									setUser({
										...d.data,
									});
								});
							})
							.then(() => {
								message.success('Processing complete!');
								navigate('/app/dashboard/creators');
							})
							.catch((err) => {
								console.log(err);
							})
							.finally(() => {
								setLoading(false);
							});
						// navigate('/dashboard');
					}}
				>
					<div className={style.content}>
						<div style={contentStyle}>
							<h2 style={{ margin: '0' }}>{steps[current].heading || ''}</h2>
							<Divider style={{ margin: '12px 0' }}></Divider>
							{steps[current].content}
						</div>
						<div className={style.buttons}>
							{current > 0 && (
								<Button style={{ margin: '0 8px' }} onClick={() => prev()}>
									Previous
								</Button>
							)}
							{current < steps.length - 1 && (
								<Button type="primary" onClick={() => next()}>
									Next
								</Button>
							)}
							{current === steps.length - 1 && (
								<Button type="primary" htmlType="submit" loading={loading}>
									Done
								</Button>
							)}
						</div>
					</div>
				</Form>
			</div>
		</Card>
	);
};

export default OnBoarding;
