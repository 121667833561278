import React from 'react';

import { EditOutlined } from '@ant-design/icons';
import { useQuery } from '@tanstack/react-query';
import {
	Checkbox,
	CheckboxProps,
	Flex,
	Form,
	Grid,
	Input,
	Modal,
	Select,
	Spin,
} from 'antd';
import { Button, List } from 'antd';
import { display } from 'html2canvas/dist/types/css/property-descriptors/display';
import { useNavigate } from 'react-router-dom';

import client from '@/lib/http/client';

import { subsriptionIcons } from './SubscriptionIcons';
import { ClientSubscription } from './subscriptionType';

const CurentSubscription = ({
	subscriptions,
	refetchSubscriptions,
}: {
	subscriptions: ClientSubscription[];
	refetchSubscriptions: () => any;
}) => {
	const navigate = useNavigate();
	const [openModal, setOpenModal] = React.useState(false);
	const [openUnsubModal, setUnsubModal] = React.useState(false);

	const [topUpButtonDisbled, setTopUpButtonDisbled] = React.useState(true);
	const [TopUpSubscrition, setTopUpSubscrition] =
		React.useState<ClientSubscription | null>(null);
	const [topUpCount, setTopUpCount] = React.useState(0);
	const [checked, setChecked] = React.useState(false);

	const unsubscribe = async () => {
		return await client.post(`/payment/unsubscribe`, {
			subscription_id: TopUpSubscrition?.subscription_id,
		});
	};

	const { data, isError, error, isFetching, refetch } = useQuery({
		queryKey: ['unsubscribe'],
		queryFn: unsubscribe,
		enabled: false,
	});

	const onChange: CheckboxProps['onChange'] = (e) => {
		setChecked(e.target.checked);
	};

	const showModal = () => {
		setOpenModal(true);
	};

	const handleCancel = () => {
		setOpenModal(false);
		setTopUpSubscrition(null);
		setTopUpCount(0);
	};
	const [form] = Form.useForm();

	const handleOk = async () => {
		navigate('/app/subscribe-top-up', {
			state: { TopUpSubscrition, topUpCount },
		});
	};

	const handleUnsubscribe = async () => {
		setUnsubModal(false);
		await refetch();
		refetchSubscriptions();
		setChecked(false);
	};

	const handleCancelUnsubscribe = () => {
		setUnsubModal(false);
		setTopUpSubscrition(null);
	};

	return (
		<>
			<Spin spinning={isFetching} delay={500}>
				<List
					style={{ marginBottom: '124px' }}
					header={
						<div
							style={{
								display: 'flex',
								justifyContent: 'space-between',
								alignItems: 'center',
							}}
						>
							<h2>Current Subscriptions</h2>
							{/* If none of the subscriptions are active, show the "New Subscription" button */}
							{!subscriptions.some((subscription) => subscription.active) && (
								<Button
									type="primary"
									size="large"
									onClick={() => navigate('/app/dashboard/subscription-plans')}
								>
									New Subscription
								</Button>
							)}
						</div>
					}
					itemLayout="vertical"
					size="large"
					dataSource={subscriptions}
					// footer={
					// 	<div>
					// 		<b>ant design</b> footer part
					// 	</div>
					// }
					renderItem={(subscription: ClientSubscription) => {
						const IconComponent = subsriptionIcons[subscription.subscription_id]
							? subsriptionIcons[subscription.subscription_id]
							: subsriptionIcons[1];
						return (
							<List.Item
								key={subscription.id}
								actions={[]}
								extra={<IconComponent />}
								style={{
									marginTop: '24px',
									padding: '16px',
									pointerEvents: subscription.active ? 'auto' : 'none',
									opacity: subscription.active ? 1 : 0.6,
									border: '1px solid #f0f0f0',
									borderRadius: '5px',
									fontSize: '16px',
								}}
							>
								<List.Item.Meta
									title={
										<div
											style={{
												display: 'flex',
												justifyContent: 'space-between',
												alignItems: 'center',
											}}
										>
											<h3>{subscription.subscription_name}</h3>
											{subscription.active && (
												<div>
													{' '}
													<Button
														// icon={<EditOutlined style={{ fontSize: '12px' }} />}
														onClick={() => {
															// setModalTags(value.tags[0]);
															// setTagsReportId(value.report_id);
															setTopUpSubscrition(subscription);
															showModal();
														}}
													>
														Top-up
													</Button>
													{subscription.status === 'active' && (
														<Button
															style={{
																marginLeft: '10px',
																color: 'red',
															}}
															// icon={<EditOutlined style={{ fontSize: '12px' }} />}
															onClick={() => {
																setTopUpSubscrition(subscription);
																setUnsubModal(true);
															}}
														>
															Unsubscribe
														</Button>
													)}
												</div>
											)}
										</div>
									}
								/>
								<p>{subscription.description}</p>
								<div
									style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}
								>
									<p>
										Billing Day of month:{' '}
										{subscription.day_of_month_period_start}
									</p>
									<div style={{ display: 'flex', gap: '20px' }}>
										<p> Total: {subscription.item_count_per_month} </p>
										<p> Used: {subscription.item_count_used}</p>
									</div>

									<p>
										{' '}
										Subscription date:{' '}
										{new Date(
											subscription.subscription_start_date,
										).toLocaleDateString('en-GB', {
											day: '2-digit',
											month: 'short',
											year: 'numeric',
										})}
									</p>

									{subscription.status === 'active' ? (
										<p>
											Next billing date:{' '}
											{new Date(
												subscription.subscription_end_date,
											).toLocaleDateString('en-GB', {
												day: '2-digit',
												month: 'short',
												year: 'numeric',
											})}
										</p>
									) : (
										<div style={{ color: 'red' }}>
											<p>Subscription canceled</p>
											<p>
												Credits expierd date:{' '}
												{new Date(
													subscription.subscription_end_date,
												).toLocaleDateString('en-GB', {
													day: '2-digit',
													month: 'short',
													year: 'numeric',
												})}
											</p>
										</div>
									)}
								</div>
								{!!subscription.sum_count_topup && (
									<div>
										<h4>Top Up</h4>
										<div style={{ display: 'flex', gap: '20px' }}>
											<p>Total: {subscription.sum_count_topup} </p>
											<p>
												Used:{' '}
												{(subscription.sum_in_process_topup || 0) +
													(subscription.sum_used_count_topup || 0)}{' '}
											</p>
										</div>
									</div>
								)}
							</List.Item>
						);
					}}
				/>

				<Modal
					destroyOnClose
					open={openModal}
					title={TopUpSubscrition?.subscription_name}
					onOk={handleOk}
					onCancel={handleCancel}
					footer={[
						<Button key="back" onClick={handleCancel}>
							Cancel
						</Button>,
						<Button
							key="submit"
							type="primary"
							disabled={topUpButtonDisbled}
							onClick={handleOk}
						>
							Continue
						</Button>,
					]}
				>
					<p>Top-up unit cost is ${TopUpSubscrition?.top_up_eur} </p>
					<div
						style={{
							width: 'fit-content',
							display: 'flex',
							flexDirection: 'column',
						}}
					>
						<label>Count required</label>
						<Form>
							<Form.Item
								label="Value"
								name="numberValue"
								rules={[
									{
										validator: (_, value) => {
											const numberValue = parseInt(value, 10);
											if (value !== numberValue.toString()) {
												setTopUpButtonDisbled(true);
												return Promise.reject('Value should be an integer');
											}
											return numberValue >= 0 && numberValue <= 10000000
												? Promise.resolve()
												: (setTopUpButtonDisbled(true),
												  Promise.reject(
														'Value should be an integer between 0 and 10000000',
												  ));
										},
									},
								]}
								validateTrigger="onChange"
							>
								<Input
									type="number"
									max={10000000}
									min={0}
									placeholder="Choose count of top-up"
									required
									// onKeyDown={(event) => event.preventDefault()}
									onChange={(value) => {
										setTopUpCount(value.target.valueAsNumber);
										setTopUpButtonDisbled(false);
									}}
								/>
							</Form.Item>
						</Form>

						<div>
							Chargable coast:
							{topUpCount
								? (TopUpSubscrition?.top_up_eur ?? 0) * topUpCount
								: 0}{' '}
							$
						</div>
					</div>

					<p>
						Note: Any top-up purchased must be used by the end of the current
						billing period{' '}
						<span>
							{' '}
							{TopUpSubscrition &&
								new Date(
									TopUpSubscrition.subscription_end_date,
								).toLocaleDateString('en-GB', {
									day: '2-digit',
									month: 'short',
									year: 'numeric',
								})}
						</span>
						. Any top-up not used by this date will lapse.
					</p>
				</Modal>

				<Modal
					destroyOnClose
					open={openUnsubModal}
					title={'Are you sure?'}
					onOk={handleUnsubscribe}
					onCancel={handleCancelUnsubscribe}
					footer={
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							<Checkbox checked={checked} onChange={onChange}>
								I have read and understood the terms and conditions.
							</Checkbox>
							<div
								style={{
									width: '100%',
									display: 'flex',
									justifyContent: 'space-between',
									margin: '16px 0',
								}}
							>
								<Button key="back" onClick={handleCancelUnsubscribe}>
									Cancel
								</Button>

								<Button
									key="submit"
									type="default"
									disabled={!checked}
									onClick={handleUnsubscribe}
									style={{ color: checked ? 'red' : '' }}
								>
									Unsubscribe
								</Button>
							</div>
						</div>
					}
				>
					<p>
						When you cancel your monthly subscription, you won't be able to
						start a new subscription until all your top-ap(s) or available
						report(s) have expired, which happens on the last day of your
						current subscription{' '}
						<span>
							{' '}
							{TopUpSubscrition &&
								new Date(
									TopUpSubscrition.subscription_end_date,
								).toLocaleDateString('en-GB', {
									day: '2-digit',
									month: 'short',
									year: 'numeric',
								})}
						</span>
						. Any unused top-ap(s) reports or remaining report(s) will be lost.
						Are you sure you want to proceed?
					</p>
				</Modal>
			</Spin>
		</>
	);
};

export default CurentSubscription;
