import React from 'react';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useLocation, useNavigate } from 'react-router-dom';

import SubscriptionCartFormItem from '@/layouts/subscription/SubscriptionCartFormItem';
import SubscriptionCartTopUp from '@/layouts/subscription/SubscriptionCartTopUp';
import { ClientSubscription } from '@/layouts/subscription/subscriptionType';
import client from '@/lib/http/client';

import CheckoutForm from '../subscribe/form';
import { FormSkeleton } from '../subscribe/formSkeleton';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY as string);
const SubscribeTopUp = () => {
	const [clientSecret, setClientSecret] = React.useState('');
	const location = useLocation();
	const subscription = location.state?.TopUpSubscrition as ClientSubscription;
	const count = location.state?.topUpCount as number;
	const navigate = useNavigate();

	React.useEffect(() => {
		if (!subscription) {
			navigate('/app/dashboard/subscription-plans');
			return;
		}

		client
			.post('/payment/pi-topup', {
				subscription_id: subscription.subscription_id,
				count: count,
			})
			.then((res) => res.data)
			.then((data) => setClientSecret(data.clientSecret));
	}, []);

	const options = {
		clientSecret,
	};

	return (
		<div
			style={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				height: '89vh',
				width: '100%',
			}}
			className="subscription-wrapper"
		>
			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					width: '100%',
				}}
			>
				{subscription && (
					<SubscriptionCartTopUp subscription={subscription} count={count} />
				)}
			</div>
			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					width: '100%',
				}}
			>
				{clientSecret ? (
					<Elements options={options} stripe={stripePromise}>
						<CheckoutForm />
					</Elements>
				) : (
					<FormSkeleton />
				)}
			</div>
		</div>
	);
};

export default SubscribeTopUp;
