import { useEffect, useState } from 'react';

import { useQuery } from '@tanstack/react-query';

import client from '@/lib/http/client';

import { ClientSubscription } from './subscriptionType';

export function useSubscription() {
	const fetchSubscriptions = async () => {
		const response = await client.get<ClientSubscription[]>(
			'/customer-subscription',
		);
		return response.data;
	};

	const {
		data: subscriptions,
		isLoading,
		isFetching,
		refetch,
	} = useQuery({
		queryKey: ['subscriptions'],
		queryFn: fetchSubscriptions,
		cacheTime: 0,
		retry: false,
	});

	const [isSubscriptionsValid, setIsSubscriptionsValid] = useState(false);
	// useEffect(() => {
	// 	refetch();
	// }, []);

	useEffect(() => {
		if (isLoading) {
			setIsSubscriptionsValid(false);
		} else if (subscriptions) {
			const today = new Date();
			const isAnySubscriptionValid = subscriptions.some((subscription) => {
				if (
					subscription.item_count_per_month +
						(subscription?.sum_count_topup ?? 0) -
						subscription.item_count_used -
						(subscription?.sum_in_process_topup ?? 0) -
						(subscription?.sum_used_count_topup ?? 0) <
					1
				) {
					return false;
				} else {
					const subscriptionEndDate = new Date(
						subscription.subscription_end_date,
					);
					return (
						subscriptionEndDate.getUTCFullYear() > today.getUTCFullYear() ||
						(subscriptionEndDate.getUTCFullYear() === today.getUTCFullYear() &&
							subscriptionEndDate.getUTCMonth() > today.getUTCMonth()) ||
						(subscriptionEndDate.getUTCFullYear() === today.getUTCFullYear() &&
							subscriptionEndDate.getUTCMonth() === today.getUTCMonth() &&
							subscriptionEndDate.getUTCDate() > today.getUTCDate())
					);
				}
			});
			setIsSubscriptionsValid(isAnySubscriptionValid);
		}
	}, [isLoading, subscriptions]);

	return {
		isSubscriptionsValid,
		subscription: subscriptions ? subscriptions : null,
		isLoading,
		isFetching,
		refetch,
	};
}
