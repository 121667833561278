import React, { useContext } from 'react';

import { Button, Spin } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';

import { SubscriptionContext } from '@/App';
import { totalAvailableReports } from '@/layouts/subscription/helper';
import { ReactComponent as SubsApproval } from '@/layouts/subscription/src/SubsApproval.svg';
import { ReactComponent as PaymentFail } from '@/layouts/subscription/src/payment-fail.svg';
import { useSubscription } from '@/layouts/subscription/useSubscription';

export const Completion: React.FC = () => {
	const navigate = useNavigate();
	const location = useLocation();

	const { refetch, subscribtionLoading } = useContext(SubscriptionContext);
	const [redirectStatus, setRedirectStatus] = React.useState<boolean>(false);

	React.useEffect(() => {
		const url = new URL(window.location.href);
		const params = new URLSearchParams(url.search);
		const status = params.get('redirect_status');
		setRedirectStatus(status === 'succeeded' ? true : false);
		refetch();
		url.search = ''; // Remove all query parameters
		// navigate(url.pathname + url.hash, { replace: true }); // Replace current entry in the history stack
	}, []);

	const onClick = () => {
		redirectStatus
			? navigate('/app/dashboard/creators')
			: navigate('/app/dashboard/subscription');
	};
	return (
		<Spin spinning={subscribtionLoading}>
			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					height: '80vh',
					width: '100%',
					flexDirection: 'column',
				}}
				className="subscription-wrapper"
			>
				<div>
					{redirectStatus ? (
						<SubsApproval
							style={{
								width: '400px',
								transform: 'scale(1)',
							}}
						/>
					) : (
						<PaymentFail
							style={{
								width: '400px',
								transform: 'scale(1)',
							}}
						/>
					)}
				</div>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						maxWidth: '500px',
						alignItems: 'center',
					}}
				>
					<h1 style={{ textAlign: 'center' }}>
						{redirectStatus
							? 'Payment sucess'
							: 'Looks like something went wrong. You might want to try again.'}
					</h1>

					<Button
						type="primary"
						onClick={onClick}
						size="large"
						style={{ width: 'fit-content' }}
					>
						{redirectStatus
							? '	Back to dashboard'
							: 'Back to subscription plans'}
					</Button>
				</div>
			</div>
		</Spin>
	);
};
