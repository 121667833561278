import React from 'react';

import { Button, Empty } from 'antd';
import { Link } from 'react-router-dom';

import style from './style.module.css';

const ComingSoon: React.FC = () => {
	const currentRouting = window.location.pathname;
	const divisions = currentRouting.split('/');
	const lastDivision =
		divisions[divisions.length - 1].charAt(0).toUpperCase() +
		divisions[divisions.length - 1].slice(1);
	return (
		<div>
			<div className={style.title}>
				<h1 className={style.link}>{lastDivision}</h1>
				<Link to={`/app/dashboard/creators`}>
					<Button>Back</Button>
				</Link>
			</div>
			<Empty
				className={style.empty}
				description="This page is coming soon! Please check back later."
				imageStyle={{
					height: 150,
					marginBottom: '1.25rem',
				}}
				style={{
					fontSize: '20px',
				}}
			/>
		</div>
	);
};

export default ComingSoon;
